<template>
  <div class="content-container">
    <div class="search-content">
      <a-form layout="inline">
        <a-form-item label="">
          <a-select v-model:value="searchForm.orgId" style="width: 140px" placeholder="部门" allowClear>
            <a-select-option v-for="item in orgList" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="searchForm.examinee" placeholder="姓名" />
        </a-form-item>
        <a-form-item label="">
          <a-select style="width: 140px" :options="stageOptions" v-model:value="searchForm.state" allowClear placeholder="阶段">
          </a-select>
        </a-form-item>
        <a-form-item label="">
          <a-month-picker v-model:value="searchForm.effectiveDate" placeholder="绩效日期" @change="effectiveDateChange" format="YYYY-MM" />
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="searchForm.empNo" placeholder="工号" />
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="searchForm.assessor" placeholder="考核人" />
        </a-form-item>
        <a-form-item label="">
          <a-select style="width: 140px" :options="perfLevelOptions" allowClear v-model:value="searchForm.perfLevel" placeholder="绩效等级">
          </a-select>
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="searchForm.totalScoreStart" placeholder="得分起" />
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="searchForm.totalScoreEnd" placeholder="得分止" />
        </a-form-item>
        <a-form-item label="">
          <a-button @click="search" :loading="loading" type="primary" style="margin-right:15px">
            搜索
          </a-button>
          <a-button @click="reset" :loading="loading">
            重置
          </a-button>
        </a-form-item>
      </a-form>
    </div>
    <a-divider />
    <div class="table-content">
      <a-space class="edit-content">
        <div>共<span style="color:#1A78FC">{{listData.length}}</span>个</div>
        <!--         <a-button @click="formulateShow=true" type="primary" class="primary-btn">制定绩效制定绩效</a-button>-->
        <a-button @click="onExport" type="primary" class="primary-btn">导出绩效</a-button>
      </a-space>
      <!-- <a-table bordered size="small" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange}" :row-key="record => record.id" :columns="columns" :pagination="pagination" :loading="loading" @change="handleTableChange" :data-source="listData"> -->
      <a-table bordered size="small" :row-key="record => record.createTime" :columns="columns" :pagination="pagination" :loading="loading" @change="handleTableChange" :data-source="listData">
        <template #time="{record}">
          <div><a @click="onDetailModel(record)">{{record.effectiveDate}}</a></div>
        </template>
        <template #status="{record}">
          <div v-if="record.state!=null">{{record.state.label}}</div>
        </template>
        <template #add="{record}">
          <a class="m-r1" v-if="record.isEditDay==1" @click="modifyDate(record)">修改日期</a>
          <a v-if="record.isSubmit==1" @click="submit(record)">提交</a>
        </template>
      </a-table>
    </div>
    <!--绩效表格-->
    <div class="ant-modal-mask" v-if="tableShow">
      <div class="ant-modal-wrap">
        <div class="table-data">
          <Table :close="close" :isLastMonth="isLastMonth" :addStatus="addStatus" :addId="addId" />
        </div>
      </div>
    </div>
  </div>
  <a-modal v-model:visible="formulateShow" title="提示">
    <div style="text-align: center">
      <h2 style="color:#1A78FC">是否复用上月模板！</h2>
    </div>
    <template #footer>
      <div>
        <a-space>
          <a-button @click="tableShow=true, addStatus=1, formulateShow=false, isLastMonth=false">不，新建绩效</a-button>
          <a-button @click="tableShow=true, addStatus=null, formulateShow=false, isLastMonth=true">确认复用</a-button>
        </a-space>
      </div>
    </template>
  </a-modal>
  <a-modal v-model:visible="dataVisible" title="修改日期" @ok="changeDataOk">
    <div class="m-b1"><span style="color:red;font-family: SimSun, sans-serif;">*</span>日期</div>
    <a-month-picker :allowClear="false" :disabled-date="disabledDate" v-model:value="changeData" placeholder="绩效日期" format="YYYY-MM" />
  </a-modal>
</template>

<script>
import { onMounted, reactive, toRefs, ref, createVNode } from 'vue'
import { useStore } from 'vuex'
import Table from '../comon/table'
import { page, perfExport, editMonth, editSubmit } from '@/api/achievement/mineCustom'
import { logicalExpression } from '@babel/types'
import moment from 'moment'
import { transitionTimeYM, confirmWindow } from '@/utils/util'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { message, Modal } from 'ant-design-vue'
export default {
  components: {
    Table
  },
  setup () {
    const store = useStore()
    const state = reactive({
      isSccuess:false,
      changeData: '',
      dataVisible: false,
      monthFormat: 'YYYY-mm',
      addStatus: null,
      addId: null,
      loading: false,
      isLastMonth: false,
      tableShow: false,
      formulateShow: false,
      mirrorSearchForm: {},
      listData: [],
      orgList: [],
      selectedRowKeys: [],
      searchForm: {
        state: null,
        effectiveDate: null,
        perfLevel: null,
        empNo: '',
        assessor: '',
        examinee: '',
        totalScoreStart: null,
        totalScoreEnd: null
      },
      columns: [
        {
          title: '绩效月份',
          align: 'center',
          dataIndex: 'scoreTotal',
          slots: {
            customRender: 'time'
          }
        },
        {
          title: '所属部门',
          align: 'center',
          dataIndex: 'orgName'
        },
        {
          title: '被考核人',
          align: 'center',
          dataIndex: 'examinee'
        },
        {
          title: '工号',
          align: 'center',
          dataIndex: 'empNo'
        },
        {
          title: '绩效名称',
          align: 'center',
          dataIndex: 'perfName'
        },
        {
          title: '得分总计',
          align: 'center',
          dataIndex: 'totalScore'
        },
        {
          title: '绩效等级',
          align: 'center',
          dataIndex: 'perfLevel'
        },
        {
          title: '考核人',
          align: 'center',
          dataIndex: 'assessor'
        },
        {
          title: '当前状态',
          align: 'center',
          slots: {
            customRender: 'status'
          }
        },
        {
          title: '方案制定',
          align: 'center',
          dataIndex: 'createTime'
        },
        {
          title: '方案审核',
          align: 'center',
          dataIndex: 'formulateTime'
        },
        {
          title: '绩效自评',
          align: 'center',
          dataIndex: 'scoreTime'
        },
        {
          title: '绩效打分',
          align: 'center',
          dataIndex: 'scoreCheckTime'
        },
        {
          title: '绩效结果确认',
          align: 'center',
          dataIndex: 'reCheckTime'
        },
        {
          title: '操作',
          align: 'center',
          dataIndex: 'add',
          slots: { customRender: 'add' }
        }],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      stageOptions: [
        {
          value:0,
          label:'保存待提交'
        },
        {
          value: 1,
          label: '方案制定'
        },
        {
          value: 2,
          label: '方案审核'
        },
        {
          value: 3,
          label: '绩效打分'
        },
        {
          value: 4,
          label: '绩效自评'
        },
        {
          value: 5,
          label: '绩效结果确认'
        }
      ],
      perfLevelOptions: [
        {
          value: 'A',
          label: 'A'
        },
        {
          value: 'B',
          label: 'B'
        },
        {
          value: 'C',
          label: 'C'
        }, {
          value: 'D',
          label: 'D'
        }, {
          value: 'E',
          label: 'E'
        }, {
          value: 'A+',
          label: 'A+'
        }]// 绩效 选择
    })
    // 提交
    const submit = (item) => {
      let content = `确认要提交${item.examinee}${item.effectiveDate}月的绩效方案吗？`
      Modal.confirm({
        title: () => '确认操作？',
        icon: () => createVNode(ExclamationCircleOutlined),
        content: () => createVNode(
          'div',
          {
            style: 'color:red;',
          },
          content || '',
        ),
        onOk () {
          editSubmit({
            id: item.id
          }).then((res) => {
            if (res.code == 10000) {
              message.success('操作成功')
              loadData()
            }
          })
        },
        onCancel () { },
      });

    }

    //编辑时间
    const changeDataOk = () => {
      if(state.isSccuess) return
      state.isSccuess = true
      editMonth({
        id: editID.value,
        yearMonth: transitionTimeYM(state.changeData)
      }).then((res) => {
        if (res.code == 10000) {
          message.success('修改成功')
          state.dataVisible = false
          loadData()
        }
      }).finally(()=>{
        state.isSccuess = false
      })
    }
    //修改日期
    const editID = ref('')
    const modifyDate = (item) => {
      state.changeData = item.effectiveDate
      editID.value = item.id
      state.dataVisible = true
    }
    // 禁用时间
    const disabledDate = current => {
      return current && current > moment().endOf('day');
    }
    const loadData = () => {
      state.loading = true
      page({
        ...state.searchForm,
        isAssessor: 1,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.pagination.current = res.data.current
          state.pagination.pageSize = res.data.size
          state.pagination.total = res.data.total
        }
      }).catch(err => { console.log('错误', err) })
        .finally(() => { state.loading = false })
    }
    const stageChange = (e, v) => {
      state.searchForm.state = v
    }
    const onSelectChange = selectedRowKeys => {
      state.selectedRowKeys = selectedRowKeys
    }
    const handleTableChange = page => {
      state.pagination.current = page.current
      state.pagination.pageSize = page.pageSize
      loadData()
    }
    const search = () => {
      state.pagination.current = 1
      loadData()
    }
    const reset = () => {
      state.searchForm = JSON.parse(state.mirrorSearchForm)
      search()
    }
    onMounted(() => {
      loadData()
      setTimeout(() => {
        state.orgList = store.state.app.orgList
      }, 1000)
      state.mirrorSearchForm = JSON.stringify(state.searchForm)
    })
    const close = () => {
      state.tableShow = false
      loadData()
    }
    // 点击查看详情
    const onDetailModel = (e) => {
      switch (e.state.value) {
        case 0:
          state.addStatus = 1
          break
        case 1:
          state.addStatus = 4
          break
        case 2:
          state.addStatus = 1
          break
        case 3:
          state.addStatus = 5
          break
        case 4:
          state.addStatus = 5
          break
        case 5:
          state.addStatus = 1
          break
        default:
          state.addStatus = 1
          break
      }
      state.addId = e.id
      // state.addId = '1555761430297100290'
      state.tableShow = true
    }
    const effectiveDateChange = (e, v) => {
      state.searchForm.effectiveDate = v
    }
    // 下载模板
    const onExport = () => {
      perfExport({
        ...state.searchForm,
        isAssessor: 1,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }, '绩效导出').then(res => {
      })
    }
    return {
      ...toRefs(state),
      search,
      reset,
      close,
      stageChange,
      onSelectChange,
      handleTableChange,
      onDetailModel,
      effectiveDateChange,
      onExport,
      modifyDate,
      changeDataOk,
      disabledDate,
      editID,
      submit
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
